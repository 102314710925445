<template>
  <div>
    <v-container pt-2 pb-1>
      <!-- <page-title title="偏好物流商" /> -->
      <v-card
        class="mx-auto my-12"
      >
        <v-card-item>
          <v-card-title class="justify-center">偏好物流商</v-card-title>
        </v-card-item>
        <v-divider class="mx-4 mb-3"></v-divider>
        <v-card-text>
          <div v-for="(item, index) in favoriteLogisticList" :key="index" align="center" class="mx-0 py-2 px-2 mb-2 d-flex align-center justify-space-between" style="border: solid 1px #bababa;">
            <span>{{ logisticList[item].name}}</span>
            <button @click="updateFavorite('delete', item)">
              <i class="ri-delete-bin-line"></i>
            </button>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mx-auto my-12"
      >
        <v-card-title class="justify-center">物流商列表</v-card-title>
        <v-divider class="mx-4 mb-3"></v-divider>
        <v-card-text>
          <div v-for="(item, index) in notFavoriteLogisticList" :key="index" align="center" class="mx-0 py-2 px-2 mb-2 d-flex align-center justify-space-between" style="border: solid 1px #bababa;">
            <span>{{ logisticList[item].name }}</span>
            <button @click="updateFavorite('add', item)">
              <i class="ri-add-line"></i>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="babel" type="text/babel">
import _cloneDeep from 'lodash/cloneDeep'

export default {
  mixins: [],
  data: () => ({
    logisticList: [],
    favoriteLogisticList: [],
    notFavoriteLogisticList: []
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    storeId() {
      return this.$store.getters['member/storeId']
    }
  },
  methods: {
    async fetchData () {
      try {
        const logistic = await this.$api.collection.favoriteLogisticApi.get(this.providerId)
        this.logisticList = logistic.map
        this.favoriteLogisticList = logistic.preference
        this.notFavoriteLogisticList = logistic.arr
      } catch (err) {
        console.log(err)
      }
    },
    async updateFavorite(type, item) {
      try {
        if (type === 'add') {
          this.favoriteLogisticList.push(item)
          this.notFavoriteLogisticList = this.notFavoriteLogisticList.filter(v => v !== item)
        }
        if (type === 'delete') {
          this.favoriteLogisticList = this.favoriteLogisticList.filter(v => v !== item)
          this.notFavoriteLogisticList.push(item)
        }
        const requestData = {
          provider_id: this.providerId,
          logistic_provider_arr: this.favoriteLogisticList
        }
        const response = await this.$api.collection.favoriteLogisticApi.update(requestData)
        // this.fetchData()
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted() {
    this.$store.dispatch('loading/active')
    try {
      await this.fetchData()
    } catch(err) {
      console.error(err)
      this.$snotify.error('讀取錯誤')
    } finally {
      this.$store.dispatch('loading/close')
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
